
import { Component } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import ImportForm from '../../components/shared/form/ImportForm.vue';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import Notification from '@/mixins/Notification';
import { namespace } from 'vuex-class';
import FoodcardApiService from '../../api/http/FoodcardApiService';
import { Venue } from '@/interfaces/models/Venue';
import { InputType } from '@/enums/InputType';
import VColumnSelect from '@/components/shared/table/VColumnSelect.vue';
import VItemSelect from '@/components/shared/table/VItemSelect.vue';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';

enum QueryField {
  NUMBER = 'number',
  OPTION_NUMBER = 'optionNumber',
  EXTERNAL_ID = 'externalId',
}

const venue = namespace('venue');

@Component({
  components: { VFormBuilder, VItemSelect, VColumnSelect, ImportForm, VWrapper },
})
export default class ImportFoodcard extends mixins(Editable, Notification) {
  @venue.State('active') public venue!: Venue;

  public isSubmitted: boolean = false;
  public $refs!: {
    form: InstanceType<typeof ImportForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
    optionNutrition: InstanceType<typeof ImportForm> & { getData: () => any };
    nutrition: InstanceType<typeof ImportForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
    articlesInfo: InstanceType<typeof ImportForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
  };
  public articleQueryField: string = QueryField.NUMBER;
  public optionsQueryField: string = QueryField.OPTION_NUMBER;

  public async save() {
    this.importFoodcard();
    this.importNutrition();
    this.importArticlesInfo();
  }

  public importFoodcard() {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (res && this.$refs.form.getData().file) {
        this.isSubmitted = true;
        const api: FoodcardApiService = new FoodcardApiService();
        const data: FormData = new FormData();
        data.append('file', this.$refs.form.getData().file);

        try {
          this.$startLoading('foodcard');
          await api.importFoodcard({ id: this.venue._id, file: data });
          this.$router.push({ name: 'article.index' });
        } finally {
          this.isSubmitted = false;
          this.$stopLoading('foodcard');
        }
      }
    });
  }

  public importNutrition() {
    this.$refs.nutrition.validate().then(async (res: boolean) => {
      if (res && this.$refs.nutrition.getData().file) {
        this.isSubmitted = true;
        const api: FoodcardApiService = new FoodcardApiService();
        const data: FormData = new FormData();
        data.append('csv', this.$refs.nutrition.getData().file);

        try {
          const optionNutrition = this.$refs.optionNutrition.getData();
          this.$startLoading('nutrition');
          await api.importNutritions({
            id: this.venue._id,
            file: data,
            articleQueryField: optionNutrition.articleQueryField,
            optionsQueryField: optionNutrition.optionsQueryField,
          });
          this.$router.push({ name: 'article.index' });
        } finally {
          this.isSubmitted = false;
          this.$stopLoading('nutrition');
        }
      }
    });
  }

  public importArticlesInfo() {
    this.$refs.articlesInfo.validate().then(async (res: boolean) => {
      if (res && this.$refs.articlesInfo.getData().file) {
        this.isSubmitted = true;
        const api: FoodcardApiService = new FoodcardApiService();
        const data: FormData = new FormData();
        data.append('csv', this.$refs.articlesInfo.getData().file);

        try {
          this.$startLoading('foodcard');
          await api.importArticlesInfo({ id: this.venue._id, file: data });
          this.$router.push({ name: 'article.index' });
        } finally {
          this.isSubmitted = false;
          this.$stopLoading('foodcard');
        }
      }
    });
  }

  get items() {
    return [
      {
        type: InputType.Select,
        name: 'articleQueryField',
        rules: 'required',
        label: 'Article Number',
        items: this.articleNumber,
        default: QueryField.NUMBER,
      },
      {
        type: InputType.Select,
        name: 'optionsQueryField',
        rules: 'required',
        label: 'Option Number',
        items: this.optionNumber,
        default: QueryField.OPTION_NUMBER,
      },
    ];
  }

  get articleNumber() {
    return [
      { text: 'Number', value: QueryField.NUMBER },
      { text: 'External Id', value: QueryField.EXTERNAL_ID },
    ];
  }

  get optionNumber() {
    return [
      { text: 'Option Number', value: QueryField.OPTION_NUMBER },
      { text: 'External Id', value: QueryField.EXTERNAL_ID },
    ];
  }
}
