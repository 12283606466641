
import { Component, Prop, Vue } from 'vue-property-decorator';
import BreadCrumb from '@/components/layout/BreadCrumb.vue';

@Component({
  components: { BreadCrumb },
})
export default class VWrapper extends Vue {
  @Prop({ type: String, default: '' }) public title!: string;
  @Prop({ type: String, default: '' }) public subTitle!: string;
  @Prop({ type: Boolean, default: false }) public disableBreadcrumbs!: boolean;
}
