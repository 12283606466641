
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class VItemSelect extends Vue {
  @Prop({ type: Array, required: true }) public source!: any[];
  @Prop({ type: String, default: '' }) public label!: string;
  public selectedItem: string[] = [];

  // call event when mount view
  /*@Emit('change')
  public mounted(): any {
  }*/

  @Watch('selectedItem')
  @Emit('change')
  public onHeaderSelectionChange() {
    return this.selectedItem;
  }
}
