
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';

@Component
export default class VColumnSelect extends Vue {
  @Prop({ type: Array, required: true }) public source!: ColumnHeader[];
  public headers: ColumnHeader[] = [];
  public selectedHeaders: string[] = [];

  @Emit('change')
  public mounted(): ColumnHeader[] {
    this.headers = this.source.filter((item: ColumnHeader) =>
      this.$vuetify.breakpoint.smAndDown ? item.defaultOnMobile : item.default,
    );
    this.selectedHeaders = this.headers.map((item: ColumnHeader) => item.value);

    return this.headers;
  }

  @Watch('selectedHeaders')
  @Emit('change')
  public onHeaderSelectionChange() {
    this.headers = this.source.filter((item: ColumnHeader) => this.selectedHeaders.indexOf(item.value) > -1);

    return this.headers;
  }
}
